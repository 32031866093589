import React from 'react';

const FormField = function ({
    name = "inputName",
    label = "inputLabel",
    type = "text",
    isRequired = false,
    defaultValue = null,
    options = null,
}) {
    switch (type) {
        case "checkbox":
            return (
                <div className="mb-4 form-checkbox">
                    <input
                        type="checkbox"
                        id={name}
                        name={name}
                        {...(isRequired ? ({ required: true }) : null)}
                    />
                    {label && (
                        <label htmlFor={name} className="text-accent-black mb-1 ml-2.5">
                        {label}{isRequired ? "" : (<small className="font-normal ml-1">(optional)</small>)}
                        </label>
                    )}
                </div>
            );
        case "select":
            return (
                <div className="mb-4">
                    {label && (
                        <label htmlFor={name} className="block font-bold text-accent-black leading-6 mb-1">
                        {label}{isRequired ? "" : (<small className="font-normal ml-1">(optional)</small>)}
                        </label>
                    )}
                    <div className="form-select-wrap">
                        <select
                            id={name}
                            name={name}
                            className="block w-full text-sm leading-6 p-2 border border-solid border-border rounded bg-gray-100 focus:outline-none"
                            {...(isRequired ? ({ required: true }) : null)}
                        >
                            {defaultValue && (
                                <option value="">{defaultValue}</option>
                            )}
                            {options && options.map((option, option_idx) => (
                                <option key={option_idx} value={option}>{option}</option>
                            ))}
                        </select>
                    </div>
                </div>
            );
        case "textarea":
            return (
                <div className="mb-4">
                    {label && (
                        <label htmlFor={name} className="block font-bold text-accent-black leading-6 mb-1">
                        {label}{isRequired ? "" : (<small className="font-normal ml-1">(optional)</small>)}
                        </label>
                    )}
                    <textarea
                        name={name}
                        id={name}
                        rows="7"
                        className="block w-full text-sm leading-6 p-2 border border-solid border-border rounded bg-gray-100 focus:outline-none overflow-auto"
                        {...(defaultValue ? ({ placeholder: defaultValue }) : null)}
                        {...(isRequired ? ({ required: true }) : null)}
                    />
                </div>
            );
        default:
            return (
                <div className="mb-4">
                    {label && (
                        <label htmlFor={name} className="block font-bold text-accent-black leading-6 mb-1">
                        {label}{isRequired ? "" : (<small className="font-normal ml-1">(optional)</small>)}
                        </label>
                    )}
                    <input
                        type={type}
                        id={name}
                        name={name}
                        className="block w-full text-sm leading-6 p-2 border border-solid border-border rounded bg-gray-100 focus:outline-none"
                        {...(defaultValue ? ({ placeholder: defaultValue }) : null)}
                        {...(isRequired ? ({ required: true }) : null)}
                    />
                </div>
            )
    }
}

export default FormField;
