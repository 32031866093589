import React from "react"
import { graphql, Link } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"

import FormField from "../components/form/FormField"
import Layout from "../components/Layout"
import SEO from "../components/SEO/SEO"
import { NewsletterBanner } from "../components/NewsletterBanner"

const shortcodes = {
  Link,
}

const PageTemplate = ({ data, location }) => {
  const page = data.mdx;
  const siteTitle = data.site.siteMetadata?.title || `Manu Sobles `;

  const formId = page.frontmatter.formId
  const formFields = page.frontmatter.formFields
  const formAction = page.frontmatter.formAction
  const formSubmitLabel = page.frontmatter.formSubmitLabel

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={page.frontmatter.title}
        description={page.frontmatter.excerpt}
        pathName={page.frontmatter.slug}
        article={false}
      />

      <section
        className="page"
        itemScope
      >
        <header className="max-w-screen-sm mx-auto mt-12 mb-10">
          <h1 itemProp="headline">{page.frontmatter.title}</h1>

          <div className="text-2xl">{page.frontmatter.description}</div>
        </header>

        <section itemProp="pageBody" className="max-w-screen-sm mx-auto mb-12">
          <MDXProvider components={shortcodes}>
            <MDXRenderer>{page.body}</MDXRenderer>
          </MDXProvider>

          <form name={formId} id={formId} action={formAction} method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
            <div className="sr-only">
              <label>Don't fill this out if you're human: <input name="bot-field" /></label>
            </div>
            <input type="hidden" name="form-name" value={formId} />

            {formFields && formFields.map((field, field_idx) => (
              <FormField
                key={field_idx}
                name={field.name}
                label={field.label}
                type={field.type}
                isRequired={field.isRequired}
                defaultValue={field.defaultValue}
                options={field.options}
              />
            ))}

            <div className="form-submit block mt-6">
              <button type="submit" className="button">{formSubmitLabel}</button>
            </div>
          </form>
        </section>

        <NewsletterBanner />
      </section>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query ContactBySlug(
    $id: String!
  ) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt
      body
      frontmatter {
        slug
        title
        description
        formId: form_id
        formAction: form_action
        formFields: form_fields {
          type: input_type
          name
          label
          defaultValue: default_value
          isRequired: is_required
          options
        }
        formSubmitLabel: form_submit_label
      }
    }
  }
`
